import React from "react"
import { graphql } from "gatsby"

import Page from "../templates/page"

const IndexPage = (props) => {
  const pageData = { contentfulPage: props.data.contentfulSite.homePage }
  return <Page data={pageData} />
}

export default IndexPage

export const query = graphql`
  {
    contentfulSite(entryTitle: { eq: "Site" }) {
      homePage {
        ...Page
      }
    }
  }
`
